import React, { useState, useEffect } from "react";
import { Contract } from '@ethersproject/contracts';
import { ethers } from "ethers";
import { useContractFunction } from '@usedapp/core';

import ApproveTokenButton from "../ApproveTokenButton";

function MintWithEthButton(props) {
    let customContract = new Contract(props.customAddress, props.customAbi, props.library)
    const { state, send } = useContractFunction(customContract, 'mintCustomooseWithEth', { transactionName: 'Mint customoose with ETH' })
    let [price, setPrice] = useState();

    useEffect(() => {
        let customContract = new Contract(props.customAddress, props.customAbi, props.library)

        async function getPrice() {
            var newPrice = await customContract.getMintPriceEth()
            newPrice = ethers.utils.formatUnits(newPrice, 'ether')
            setPrice(newPrice)
        }
        getPrice()
    }, [props])

    const onMintPressed = async () => {
        if (props.desiredConfig == '000000000') {
            alert("Add traits to mint a custom moose, or mint an empty frame")
            return
        } else if (parseFloat(props.traxBalance) < parseFloat(props.customizationPrice)) {
            alert("You don't have enough TRAX for this CustoMoose")
            return
        }
        let txnPrice = ethers.utils.parseUnits((price * 1).toFixed(8), 'ether')
        send(props.desiredConfig, { value: txnPrice, gasLimit: 360000 })
        return
    };

    return (
        <div className="my-2">
        {
            props.allowance < props.customizationPrice ?
                <ApproveTokenButton {...props} />
                :
                <button className='btn-light mx-1 px-2 py-.75 rounded-sm' id="mintWithEthButton" onClick={onMintPressed} disabled={false}>
                    Mint custom with ETH
                </button>
        }
        <p>Price: {price} ETH + {props.customizationPrice} TRAX</p>
        </div>
    )
}

export default MintWithEthButton
