import React, { useState, useEffect } from "react";
import { Contract } from '@ethersproject/contracts';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './index.css';

var _ = require('lodash');

function StakedMooseGallery(props) {
    let [tokens, setTokens] = useState();
    let [imgSvgs, setImgSvgs] = useState();

    useEffect(() => {
        let traxContract = new Contract(props.tokenAddress, props.tokenAbi, props.library)

        async function getTokens() {
            var newTokens = await traxContract.getTokensStaked(props.account)
            newTokens = newTokens.map((val) => { return val.toNumber() })
            newTokens = _.sortBy(newTokens)
            setTokens(newTokens)
        }

        getTokens();
    }, [props]);

    useEffect(() => {
        let mooseContract = new Contract(props.nftAddress, props.nftAbi, props.library)

        async function getImages() {
            var allSvgs = []
            for (var tkn_id of tokens) {
                try {
                    var rawUri = await mooseContract.tokenURI(tkn_id)
                    var decodedUri = atob(rawUri.split(',')[1])
                    var encodedSvg = JSON.parse(decodedUri)['image']
                    allSvgs.push({
                        'token_id': tkn_id,
                        'img_svg': encodedSvg
                    })
                } catch {
                    allSvgs.push({
                        'token_id': tkn_id,
                        'img_svg': ''
                    })
                }
            }
            setImgSvgs(allSvgs)
        }

        if (tokens) {
            getImages()
        }
    }, [props, tokens])

    if (tokens && imgSvgs) {
        return (
            <div className="container my-3 w-4/6 p-3 bg-gray-100 bg-opacity-25">
                <h2>
                    Staked
                </h2>
                <div className="grid grid-cols-6">
                    {
                        imgSvgs.map((imgObj) => {
                            let isSelected = imgObj['token_id'] == props.selectedToken
                            return (
                                <div>
                                    <img key={imgObj['token_id']} tokenid={imgObj['token_id']} className={`gallery-img ${isSelected ? "selected" : ""}`} src={imgObj['img_svg']} alt="NFT" />
                                    <p>#{imgObj['token_id']}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    } else {
        return (
            <p>Loading...</p>
        )
    }
}

export default StakedMooseGallery
