import React, { useReducer } from "react";
import { Contract } from '@ethersproject/contracts';
import { useContractFunction } from '@usedapp/core';

function BurnFrameButton(props) {
    let customContract = new Contract(props.customAddress, props.customAbi, props.library)
    const { state, send } = useContractFunction(customContract, 'burnFrameForTrax', { transactionName: 'Burn frame for TRAX' })

    const onButtonPressed = async () => {
        if (props.selectedToken >= 0) {
            send(props.selectedToken, { gasLimit: 180000 })
        } else {
            alert("Your inputs suck")
        }
        return
    };

    return (
        <form autoComplete="off" className="flex ml-3">
            <button className='btn px-2 text-black bg-gray-200 rounded-r-sm' type="button" id="burnButton" onClick={onButtonPressed} disabled={false}>
                Burn Frame
            </button>
        </form>
    )
}

export default BurnFrameButton
