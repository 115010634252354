const LETTERS = [
    "0", "1", "2", "3", "4", "5", "6", "7", "8", "9",
    "a", "b", "c", "d", "e", "f", "g", "h", "i", "j",
    "k", "l", "m", "n", "o", "p", "q", "r", "s", "t",
    "u", "v", "w", "x", "y", "z"
];

function letterToNumber(position) {
    return LETTERS[position]
}

function setCharAt(str, index, chr) {
    if (index > str.length - 1) return str;
    return str.substring(0, parseInt(index)) + chr + str.substring(parseInt(index) + 1);
}

module.exports = {
    setCharAt: setCharAt,
    letterToNumber: letterToNumber
}