import React, { useState } from 'react';
import {
  Link
} from "react-router-dom";
import { useEthers } from '@usedapp/core';
import './index.css';

function Navbar(props) {
  const [navLinkOpen, navLinkToggle] = useState(false)
  const { activateBrowserWallet } = useEthers()

  const handleNavLinksToggle = () => {
    navLinkToggle(!navLinkOpen);
  };

  const renderClasses = () => {
    let classes = "navLinks flex justify-around items-center"
    if (navLinkOpen) {
      classes += " active"
    }
    return classes
  }

  return (
    <nav className="bg-gray-900 bg-opacity-80 px-5 flex items-center justify-between">
      <div className="logo flex ml-3 flex-row items-center">
        <i className="fas fa-mountain"></i>
        <a className="link text-3xl" href="/"><h4 className="whitespace-nowrap">Moose Trax</h4></a>
        <a className="mx-1" href="https://twitter.com/MooseTraxNFT"> <i className="fab fa-twitter fa-sm"></i></a>
        <a className="mx-1" href="https://discord.gg/QRVnktu5"><i className="fab fa-discord fa-sm"></i></a>
        <a className="mx-1" href="https://medium.com/@moosetraxnft"><i className="fab fa-medium fa-sm"></i></a>
      </div>

      <ul className={renderClasses()}>
        <li className="link">
          <Link to="/">Home</Link>
        </li>
        <li className="link">
          <Link to="/about">About</Link>
        </li>
        <li className="link">
          <Link to="/staking">Staking</Link>
        </li>
        <li className="link">
          <Link to="/customoose">CustoMoose</Link>
        </li>
        <li className="link">
          <a href="https://opensea.io/collection/moose-trax-nft" target="_blank" rel="noreferrer">Opensea</a>
        </li>
        <li className="link">
          <a href="https://v1.moosetrax.art/" target="_blank" rel="noreferrer">V1</a>
        </li>
        <li>
          <button id='connect-btn' className="btn-light my-1 px-6 py-1" onClick={() => activateBrowserWallet()}>
            {props.account ?
              props.chainId === 1 ?
                <p>{props.account.slice(0, 4) + "..." + props.account.slice(props.account.length-4, props.account.length)}</p>
                :
                "Wrong network"
              : "Connect"            
            }
          </button>
        </li>
      </ul>
      <div onClick={handleNavLinksToggle} className="hamburger-toggle">
        <i className="fas fa-bars fa-lg"></i>
      </div>
    </nav>
  )    
}
 
export default Navbar;