import React, { useState, useEffect } from "react";
import { Contract } from '@ethersproject/contracts';
import { ethers } from "ethers";
import { setCharAt, letterToNumber } from '../../utils/strings.js';
import './index.css';

var _ = require('lodash');

let TRAITS = [
    "Background",
    "Body",
    "Eye",
    "Antler",
    "Hat",
    "Neck",
    "Mouth",
    "Nose",
    "Accessory"
]

function Traits(props) {
    let [imgSvgs, setImgSvgs] = useState();

    useEffect(() => {
        let customContract = new Contract(props.customAddress, props.customAbi, props.library)

        async function getImages() {
            let rawConfig = '000000000'
            var newImgSvgs = {}
            for(let [traitIndex, traitValues] of props.traits.entries()) {
                var allSvgs = []
                // for (var i = 0; i < traitValues.length; i++) {
                var layerComplete = false;
                for (var i = 0; i < 30; i++) {
                    if(layerComplete) continue;
                    try {
                        // get the svg
                        var thisConfig = setCharAt(rawConfig, traitIndex, letterToNumber(i))
                        var rawSvg = await customContract.configToSVG(thisConfig)
                        var encodedSvg = btoa(rawSvg)

                        // get the price
                        var rawPrice = await customContract.getTraitPrice(traitIndex, i)
                        var traitPrice = rawPrice.toNumber() / 100

                        allSvgs.push({
                            'token_config': thisConfig,
                            'img_svg': 'data:image/svg+xml;base64,' + encodedSvg,
                            'price': traitPrice
                        })
                    } catch {
                        layerComplete = true;
                        // allSvgs.push({
                        //     'token_config': thisConfig,
                        //     'img_svg': ''
                        // })
                    }
                }
                newImgSvgs[traitIndex] = allSvgs
            }
            setImgSvgs(newImgSvgs)
        }

        getImages()
    }, [])

    function handleSelected(index, value) {
        if(props.selectedTraits[index] === value) {
            var newSelectedTraits = props.selectedTraits
            delete newSelectedTraits[index]
            props.selectedTraitsHandler({
                ...newSelectedTraits
            })
        } else {
            props.selectedTraitsHandler({
                ...props.selectedTraits,
                [index]: value
            })
        }
    }

    if (imgSvgs) {
        var final = []
        for (var [i, val] of [0, 1, 2, 3, 4, 5, 6, 7, 8].entries()) {
            final.push(
                <div className="container mt-2 w-full">
                    <p>
                        {TRAITS[i]}
                    </p>
                    <div className="">
                        {
                            imgSvgs[i].map((imgObj, imgInd) => {
                                let traitindex = i;
                                let isSelected = props.selectedTraits[i] === letterToNumber(imgInd);
                                return (
                                    <div id={i + ":" + imgInd} key={i + ":" + imgInd} className="flex flex-col">
                                        <img className='gallery-img' className={`gallery-img ${isSelected ? "selected" : ""}`} src={imgObj['img_svg']} alt="NFT" onClick={() => handleSelected(traitindex, letterToNumber(imgInd))} />
                                        <p className='text-sm'>{imgObj['price']}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            )
        }
        return (
            <div className="p-3 bg-gray-100 bg-opacity-25">
                <h2>Choose your traits</h2>
                <div className="grid grid-cols-9">
                    {final}
                </div>
            </div>
        )
    } else {
        return (
            <p>Loading traits...</p>
        )
    }
}

export default Traits
