import React, { useReducer } from "react";
import { Contract } from '@ethersproject/contracts';
import { useContractFunction } from '@usedapp/core';

const formReducer = (state, event) => {
    return {
        ...state,
        [event.name]: event.value
    }
}

function StakeButton(props) {
    const [formData, setFormData] = useReducer(formReducer, {});
    let tokenContract = new Contract(props.tokenAddress, props.tokenAbi, props.library)
    const { state, send } = useContractFunction(tokenContract, 'stakeByIds', { transactionName: 'Stake by ids' })

    const handleChange = event => {
        setFormData({
            name: event.target.name,
            value: event.target.value,
        });
    }

    const onStakePressed = async () => {
        let input = formData.tokenIds
        let regex = /[0-9]+(,[0-9]+)*/g

        if(regex.test(input)) {
            input = input.split(',')
            send(input)
        }
        return
    };

    return (
        <form autoComplete="off" className="flex mx-2">
            <input className="pl-1 text-black focus:outline-none w-64" type="input" name="tokenIds" placeholder="Enter IDs to stake" onChange={handleChange} />
            <button className='btn px-2 text-black bg-gray-200 rounded-r-sm' type="button" id="stakeByIdButton" onClick={onStakePressed} disabled={props.disabled}>
                Stake Token IDs
            </button>
        </form>
    )
}

export default StakeButton
