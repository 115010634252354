import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { Contract } from '@ethersproject/contracts';
import './index.css';

import ClaimButton from '../ClaimButton';

function Balances(props) {
    let [traxBalance, setTraxBalance] = useState(0);
    let [mooseBalance, setMooseBalance] = useState(0);
    let [stakedMooseBalance, setStakedMooseBalance] = useState(0);
    let [custoBalance, setCustoBalance] = useState(0);
    let [rewards, setRewards] = useState();

    useEffect(() => {
        let nftContract = new Contract(props.nftAddress, props.nftAbi, props.library)
        let tokenContract = new Contract(props.tokenAddress, props.tokenAbi, props.library)
        let customContract = new Contract(props.customAddress, props.customAbi, props.library)

        async function getTraxBalance() {
            var newBalance = await tokenContract.balanceOf(props.account)
            newBalance = ethers.utils.formatUnits(newBalance, 'ether')
            setTraxBalance(newBalance)
        }

        async function getMooseBalance() {
            var newBalance = await nftContract.balanceOf(props.account)
            newBalance = newBalance.toNumber()
            setMooseBalance(newBalance)
        }

        async function getStakedMooseBalance() {
            var newStaked = await tokenContract.getTokensStaked(props.account)
            setStakedMooseBalance(newStaked.length)
        }

        async function getCustomooseBalance() {
            var newBalance = await customContract.balanceOf(props.account)
            newBalance = newBalance.toNumber()
            setCustoBalance(newBalance)
        }

        async function getRewardsBalance() {
            var newRewards = await tokenContract.getAllRewards(props.account)
            newRewards = ethers.utils.formatUnits(newRewards, 'ether')
            setRewards(parseFloat(newRewards))
        }

        getTraxBalance()
        getMooseBalance()
        getStakedMooseBalance()
        getRewardsBalance()
        getCustomooseBalance()
    }, [props])

    let loaded = (traxBalance >= 0)
    if(loaded) {
        return (
            <div className="my-2 p-3 text-base bg-gray-800 bg-opacity-70 rounded-lg w-3/5">
                <div className="mt-2 mb-4">
                    <h2>Balances</h2>
                </div>
                <div className="grid grid-cols-4 items-start">
                    <div className="flex flex-col">
                        <p className='status-value'> {mooseBalance + stakedMooseBalance} </p>
                        <p className='status-title'>MOOSE</p>
                    </div>
                    <div className="flex flex-col">
                        <p className='status-value'> {custoBalance} </p>
                        <p className='status-title'>FRAME</p>
                    </div>
                    <div className="flex flex-col">
                        <p className='status-value'> {parseFloat(traxBalance).toFixed(2)} </p>
                        <p className='status-title'>TRAX</p>
                    </div>
                    <div className="mx-4 flex flex-col">
                        <p className='status-value'> {parseFloat(rewards).toFixed(6)} </p>
                        <p className='status-title'>Unclaimed TRAX</p>
                        <ClaimButton
                            {...props}
                            amount={rewards}
                            disabled={rewards === 0}
                        />
                        <p className='status-title text-sm'>* Official claim period ended on 1/1/2022</p>
                        <p className='status-title text-sm'>TRAX can be claimed by unstaking your Moose</p>
                    </div>
                </div>
            </div>
        )
    } else {
        return(
            <p>Loading...</p>
        )
    }
}

export default Balances
