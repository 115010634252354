import React, { useState, useEffect } from "react";
import { Contract } from '@ethersproject/contracts';
import './index.css';

function Status(props) {
    let [supply, setSupply] = useState();
    let [burned, setBurned] = useState();
    let [staked, setStaked] = useState();

    useEffect(() => {
        let nftContract = new Contract(props.nftAddress, props.nftAbi, props.library)

        async function getSupply() {
            var newSupply = await nftContract.totalSupply()
            newSupply = newSupply.toNumber()
            setSupply(newSupply)
        }

        async function getBurned() {
            var newBurned = await nftContract.walletOfOwner(props.burnAddress)
            newBurned = newBurned.length
            setBurned(newBurned)
        }

        async function getStaked() {
            var newStaked = await nftContract.walletOfOwner(props.tokenAddress)
            newStaked = newStaked.length
            setStaked(newStaked)
        }

        getSupply()
        getBurned()
        getStaked()
    }, [props])

    let loaded = (supply >= 0) && (burned >= 0) && (staked >= 0)
    if(loaded) {
        return (
            <div className="grid grid-cols-4 p-3 text-base bg-gray-800 bg-opacity-70 rounded-lg w-3/5 items-center">
                <div>
                    <p className='status-value'> {supply} / 10000 </p>
                </div>
                <div>
                    <p className='status-value'> {staked} </p>
                </div>
                <div>
                    <p className='status-value'> {supply - burned - staked} </p>
                </div>
                <div>
                    <p className='status-value'> {burned} </p>
                </div>

                <div>
                    <p className='status-title'>Minted</p>
                </div>
                <div>
                    <p className='status-title'>Staked</p>
                </div>
                <div>
                    <p className='status-title'>In wallets</p>
                </div>
                <div>
                    <p className='status-title'>Burned</p>
                </div>
            </div>
        )
    } else {
        return(
            <p>Loading...</p>
        )
    }
}

export default Status
