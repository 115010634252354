import React from 'react';
import {
  Link
} from "react-router-dom";

function Home(props) {
    return (
      <div className="showcase grid justify-items-center">
        <h2 className="ml-3 font-black text-left">
          Releases
        </h2>
        <div className='container card mt-2 pb-3'>
          <h3 className="mt-4 mx-3 text-2xl font-black text-left">
            CustoMoose (FRAME)
          </h3>
          <p className="mx-8 text-left">
            CustoMoose is a release of fully customizable NFT pixel art. This new drop will provide continuing utility for TRAX by allowing holders to spend TRAX on traits to customize their FRAME.
            Unlike a Moose Trax OG, traits can be exchanged and removed via the <Link className="underline" to="/customoose">dapp</Link>. The dapp will offer a library of traits for you to deck out your CustoMoose.
          </p>

          <p className="mt-8 mx-8 text-left">
            FRAME tokens will be available for purchase using TRAX or ETH. FRAME holders can customize their frame by choosing their desired traits and supplying the required amount of TRAX.
            TRAX paid for customizations is immediately removed from circulation, thereby reducing supply. Burning a FRAME will release 80% of this stored TRAX.
          </p>

          <p className="mt-4 mx-8 text-left">
            Pricing:
          </p>
          <ul className="text-left list-disc ml-16">
            <li>Starts at 10 TRAX per FRAME. Every 1000 FRAMEs minted will increase the price by 10 TRAX</li>
            <li>Starts at .07 ETH per FRAME. Every 12 hours the price will be reduced by .005 ETH</li>
          </ul>

          <p className="mt-4 mx-8 text-left">
            More details in the <a className="underline" href="https://medium.com/@moosetraxnft/customoose-is-here-95ecddf53578">drop announcement</a>
          </p>
        </div>

        <div className='container card mt-5 pb-3'>
          <h3 className="mt-4 ml-3 text-2xl font-black text-left">
            Moose Trax OG (<a className="underline" href="https://opensea.io/collection/moose-trax-nft">MOOSE</a>)
          </h3>
          <p className="mx-8 text-left">
            Drop 10,000 unique on-chain NFT avatars. The drop will have the following mechanics:
          </p>
          <ul className="text-left list-disc ml-16 mr-8">
            <li>Each Moose can be staked to receive 1 TRAX per Moose per day.</li>
            <li>Until 10,000 Moose have been minted, a holder can choose to burn a Moose to mint a new one. This reduces overall supply and offers a chance to re-roll traits.</li>
            <li>After the first 2,000 Moose are minted, you can only mint a Moose using TRAX. This incentivizes early holders to stake their Moose and gives them the opportunity to mint later.</li>
          </ul>

          <p className="mt-4 mx-8 text-left">
            Pricing:
          </p>
          <ul className="text-left list-disc ml-16">
            <li>1-2000: .03 ETH, with a price reduction of .005 ETH every 6 hours.</li>
            <li>2001-4000: 1 TRAX</li>
            <li>4001-6000: 2 TRAX</li>
            <li>6001-8000: 3 TRAX</li>
            <li>8001-10000: 4 TRAX</li>
          </ul>
        </div>

        <div className='container card'>
          <h2 className="ml-3 font-black text-left">
            FAQ
          </h2>
          <ul className="text-left ml-5 mr-5 mb-2">
            <li className = "text-yellow-300 font-bold">Q: How many Moose Trax OGs are in circulation?</li>
            <li className = "mb-3">A: There are only 7734 remaining moose. A total of 2266 moose were burned by the community during the mint.</li>
            <li className = "text-yellow-300 font-bold">Q: Should I burn a moose now?</li>
            <li className = "mb-3">A: No, burning was a mechanic used during the mint.</li>
            <li className = "text-yellow-300 font-bold">Q: What is staking and how can I stake my moose?</li>
            <li className = "mb-3">A: Staking locks your moose in the staking contract and starts accumulating TRAX. Staking can be accessed through the staking interface (link in nav).</li>
            <li className = "text-yellow-300 font-bold">Q: What is TRAX?</li>
            <li className = "mb-3">A: TRAX is a utility token used to access Moose Trax mints and to customize FRAMEs.</li>
            <li className = "text-yellow-300 font-bold">Q: What does "on chain" mean?</li>
            <li className = "mb-3">A: NFT metadata is stored either on-chain or off-chain. On-chain means direct storage of the metadata and image in a smart contract on Ethereum. Other projects might use off-chain storage like IPFS, or retrieve the image from an API that they maintain.</li>
          </ul>
        </div>
        <div className='container card'>
          <h2 className="ml-3 font-black text-left">
            Links
          </h2>
          <ul className="text-left ml-5 mr-5 mb-2">
            <li className=""><a className="underline" href="https://etherscan.io/address/0x3146dd9c200421a9c7d7b67bd1b75ba3e2c15310">MOOSE Contract</a></li>
            <li className=""><a className="underline" href="https://etherscan.io/address/0xc92e730eebaca3d16d6c17f7f4646dce923663e8">TRAX Contract</a></li>
            <li className=""><a className="underline" href="https://etherscan.io/address/0xc91D89828Cd0d635d0475eC6785c497dC1bF240F">FRAME Contract</a></li>
          </ul>
        </div>
      </div>
    )
}
 
export default Home;