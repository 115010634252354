import React, { useState, useEffect } from "react";
import { Contract } from '@ethersproject/contracts';
import { ethers } from "ethers";
import { setCharAt, letterToNumber } from '../../utils/strings.js';

// components
import MintWithTraxButton from "../MintWithTraxButton";
import MintWithEthButton from "../MintWithEthButton";
import MintCustomWithTraxButton from "../MintCustomWithTraxButton";
import MintCustomWithEthButton from "../MintCustomWithEthButton";

// styles
import './index.css';

var _ = require('lodash');

const BLANK_SVG = 'data:image/svg+xml;base64,PHN2ZyBpZD0ibW9vc2Utc3ZnIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaW5ZTWluIG1lZXQiIHZpZXdCb3g9IjAgMCAzMiAzMiI+PHN0eWxlPiNtb29zZS1zdmd7c2hhcGUtcmVuZGVyaW5nOiBjcmlzcGVkZ2VzO308L3N0eWxlPjwvc3ZnPg=='
const ERROR_SVG = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjIiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCgkgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIzMnB4IiBoZWlnaHQ9IjMycHgiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaW5ZTWluIG1lZXQiIHZpZXdCb3g9IjAgMCAzMiAzMiI+CjxwYXRoIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYsIDgpIiBmaWxsPSIjQkIwQjJGIiBkPSJNMTAsMEwwLDE2aDIwTDEwLDB6IE0xMSwxMy45MDhIOXYtMmgyVjEzLjkwOHogTTksMTAuOTA4di02aDJ2Nkg5eiIvPgo8L3N2Zz4K'

function Mint(props) {
    let [desiredConfig, setDesiredConfig] = useState('000000000');
    let [imgSvg, setImgSvg] = useState(BLANK_SVG);
    let [supply, setSupply] = useState();
    let [price, setPrice] = useState(0);
    let [allowance, setAllowance] = useState();
    let [traxBalance, setTraxBalance] = useState();

    let customContract = new Contract(props.customAddress, props.customAbi, props.library)

    useEffect(() => {
        async function getSupply() {
            var newSupply = await customContract.totalSupply()
            newSupply = newSupply.toNumber()
            setSupply(newSupply)
        }

        getSupply()
    }, [props])
    
    useEffect(() => {
        let customContract = new Contract(props.customAddress, props.customAbi, props.library)

        async function getImage() {
            try {
                var rawSvg = await customContract.configToSVG(desiredConfig)
                var encodedSvg = btoa(rawSvg)
                setImgSvg('data:image/svg+xml;base64,' + encodedSvg)
            } catch {
                setImgSvg(ERROR_SVG)
            }
        }

        async function getPrice() {
            var newPrice = await customContract.getMintCustomizationPrice(desiredConfig)
            newPrice = ethers.utils.formatUnits(newPrice, 'ether')
            setPrice(newPrice)
        }

        getImage()
        if (desiredConfig) {
            getPrice()
        }
    }, [props, desiredConfig])

    useEffect(() => {
        let tokenContract = new Contract(props.tokenAddress, props.tokenAbi, props.library)

        async function getDesiredConfig() {
            var newDesiredConfig = '000000000'
            for (let [traitIndex, traitValue] of Object.entries(props.selectedTraits)) {
                newDesiredConfig = setCharAt(newDesiredConfig, traitIndex, traitValue)
            }
            setDesiredConfig(newDesiredConfig)
        }

        async function getAllowance() {
            var newAllowance = await tokenContract.allowance(props.account, props.customAddress)
            newAllowance = ethers.utils.formatUnits(newAllowance, 'ether')
            setAllowance(parseFloat(newAllowance))
        }

        async function getTraxBalance() {
            var newBalance = await tokenContract.balanceOf(props.account)
            newBalance = ethers.utils.formatUnits(newBalance, 'ether')
            setTraxBalance(newBalance)
        }

        if (props.selectedTraits) {
            getDesiredConfig()
        }
        getAllowance()
        getTraxBalance()
    }, [props])

    if (true) {
        return (
            <div className="my-4 grid w-4/6 justify-items-center items-center p-3 bg-gray-100 bg-opacity-25">
                <h2>Mint</h2>
                <img key="customoose-preview" className="w-2/6 mb-2 gallery-img" src={imgSvg} alt="NFT" />
                <div className="flex w-full flex-row justify-evenly">
                    <div className="w-2/5">
                        <p>TRAX Minting</p>
                    </div>
                    <div className="border-l-2 border-gray-100"></div>
                    <div className="w-2/5">
                        <p>ETH Minting</p>
                    </div>
                </div>
                <div className="flex w-full flex-row justify-evenly">
                    {
                        Date.now() > 1639418400000 ?
                        <div className="w-2/5">
                            <MintWithTraxButton {...props} allowance={allowance} />
                        </div>
                        :
                        <div className="w-2/5">
                            <p className="my-2">TRAX Minting is not open</p>
                        </div>
                    }
                    <div className="border-l-2 border-gray-100"></div>
                    {
                        Date.now() > 1639504800000 ?
                            <div className="w-2/5">
                                <MintWithEthButton {...props} />
                            </div>
                            :
                            <div className="w-2/5">
                                <p className="my-2">ETH Minting is not open</p>
                            </div>
                    }
                </div>
                <div className="w-5/6 border-b-2 border-gray-100"></div>
                <div className="flex w-full flex-row justify-evenly">
                    {
                        Date.now() > 1639418400000 ?
                            <div className="w-2/5">
                                <MintCustomWithTraxButton {...props} desiredConfig={desiredConfig} traxBalance={traxBalance} customizationPrice={price} allowance={allowance} />
                            </div>
                            :
                            <div className="w-2/5">
                                <p className="my-2">TRAX Minting is not open</p>
                            </div>
                    }
                    <div className="border-l-2 border-gray-100"></div>
                    {
                        Date.now() > 1639504800000 ?
                            <div className="w-2/5">
                                <MintCustomWithEthButton {...props} desiredConfig={desiredConfig} traxBalance={traxBalance} customizationPrice={price} allowance={allowance} />
                            </div>
                            :
                            <div className="w-2/5">
                                <p className="my-2">ETH Minting is not open</p>
                            </div>
                    }
                </div>
                <h2>{supply} / 10000 minted</h2>
            </div>
        )
    } else {
        return (
            <p>Select a frame</p>
        )
    }
}

export default Mint
