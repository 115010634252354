import React, { useState, useEffect } from "react";
import { Contract } from '@ethersproject/contracts';
import { ethers } from "ethers";
import { useContractFunction } from '@usedapp/core';

import ApproveTokenButton from "../ApproveTokenButton";

function MintWithTraxButton(props) {
    let customContract = new Contract(props.customAddress, props.customAbi, props.library)
    const { state, send } = useContractFunction(customContract, 'mintFrameWithTrax', { transactionName: 'Mint empty frame with TRAX' })
    let [price, setPrice] = useState();
    let [quantity, setQuantity] = useState(1);

    const handleChange = (event) => {
        let qty = event.target.value
        setQuantity(qty)
    }

    useEffect(() => {
        let customContract = new Contract(props.customAddress, props.customAbi, props.library)

        async function getPrice() {
            var newPrice = await customContract.getMintPriceTrax()
            newPrice = ethers.utils.formatUnits(newPrice, 'ether')
            setPrice(newPrice)
        }
        getPrice()
    }, [props])

    const onMintPressed = async () => {
        send(quantity)
        return
    };

    return (
        <div className="my-2">
            {
            props.allowance < price ?
                <ApproveTokenButton {...props} />
            :
                <div className="flex mx-2 justify-center">
                    <input className="w-16 text-black" type="number" id="quantity" name="quantity" min="1" max="20" defaultValue="1" onChange={handleChange} />
                    <button className='btn-light mx-1 px-2 py-.75 rounded-sm' id="mintWithTraxButton" onClick={onMintPressed} disabled={false}>
                        Mint empty frame with TRAX
                    </button>
                </div>
            }
            <p>Price: {price * quantity} TRAX</p>
        </div>
    )
    
}

export default MintWithTraxButton
