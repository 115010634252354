import React, { useState, useEffect } from "react";
import { Contract } from '@ethersproject/contracts';
import './index.css';

import ApproveItemButton from '../ApproveItemButton';
import StakeButton from '../StakeButton';
import UnstakeButton from '../UnstakeButton';
import UnstakeAllButton from '../UnstakeAllButton';
var _ = require('lodash');

function StakingInterface(props) {
    let [tokens, setTokens] = useState();
    let [approved, setApproval] = useState();

    useEffect(() => {
        let nftContract = new Contract(props.nftAddress, props.nftAbi, props.library)
        let tokenContract = new Contract(props.tokenAddress, props.tokenAbi, props.library)

        async function getTokens() {
            var newStaked = await tokenContract.getTokensStaked(props.account)
            var accountTokens = await nftContract.walletOfOwner(props.account)
            
            accountTokens = accountTokens.map((val) => { return val.toNumber() })
            newStaked = newStaked.map((val) => { return val.toNumber() })

            var newTokens = {
                'owned': _.sortBy(accountTokens),
                'staked': _.sortBy(newStaked)
            }
            setTokens(newTokens)
        }

        async function getApproval() {
            var newApproval = await nftContract.isApprovedForAll(props.account, props.tokenAddress)
            setApproval(newApproval)
        }

        getTokens();
        getApproval();
    }, [props]);

    if(tokens && (typeof approved !== 'undefined')) {
        return (
            <div>
                <div className='flex flex-row my-4 justify-around'>
                    <div>
                        <ApproveItemButton
                            disabled={tokens.owned.length === 0}
                            {...props}
                        />
                    </div>
                    <div>
                        <UnstakeAllButton
                            disabled={tokens.staked.length === 0}
                            {...props}
                        />
                    </div>
                </div>
                <div className='flex flex-row my-4 justify-around'>
                    <div>
                        <StakeButton
                            disabled={tokens.owned.length === 0 || tokens.staked.length === 10 || !approved}
                            {...props}
                        />
                    </div>
                    <div>
                        <UnstakeButton
                            disabled={tokens.staked.length === 0}
                            {...props}
                        />
                    </div>
                </div>
            </div>
        )
    } else {
        return(
            <p>Loading...</p>
        )
    }
}

export default StakingInterface
