import React from "react";
import { Contract } from '@ethersproject/contracts';
import { useContractFunction } from '@usedapp/core';

let APPROVE_AMOUNT = '115792089237316195423570985008687907853269984665640564039457584007913129639935';

function ApproveTokenButton(props) {
    let tokenContract = new Contract(props.tokenAddress, props.tokenAbi, props.library)

    const { state, send } = useContractFunction(
        tokenContract,
        'approve'
    )

    const onApprovePressed = async () => {
        send(props.customAddress, APPROVE_AMOUNT)
        return
    };

    return (
        <button className="btn-light mx-1 px-2 py-.75 rounded-sm" id="approveTokenButton" onClick={onApprovePressed}>
            Approve TRAX
        </button>
    )
}

export default ApproveTokenButton
