import React, { useEffect, useState } from "react";

// components
import Status from "../Status";
import Balances from "../Balances";
import MooseGallery from "../MooseGallery";
import StakedMooseGallery from "../StakedMooseGallery";
import StakingInterface from "../StakingInterface";

// styles
import './index.css';

function Staking(props) {
  let loaded = props.burnAddress && props.nftAddress && props.nftAbi && (props.chainId == 1)
  if(loaded) {
    return (
      <div className="showcase grid justify-items-center overflow-y-hidden">
        <Status {...props} />
        <Balances {...props} />
        <MooseGallery {...props} />
        <StakedMooseGallery {...props} />
        <StakingInterface {...props} />
      </div>
    )
  } else {
    return (
      <div className= "showcase grid justify-items-center overflow-y-hidden">
        <p>Connect to Ethereum Mainnet to continue</p>
      </div>
    )
  }
}

export default Staking;
