import React, { useEffect, useState } from "react";

// components
import Gallery from "../Gallery";
import Mint from "../Mint";
import Traits from "../Traits";
import Balances from "../Balances";
import Customize from "../Customize";

function Customoose(props) {
  let [selectedTraits, setSelectedTraits] = useState({})
  let [selectedToken, setSelectedToken] = useState()

  function selectedTokenHandler(selectedTokenValue) {
    setSelectedToken(selectedTokenValue)
    setSelectedTraits({})
  }

  function selectedTraitsHandler(selectedTraitsValue) {
    setSelectedTraits(selectedTraitsValue)
  }

  let loaded = props.burnAddress && props.nftAddress && props.nftAbi && (props.chainId == 1)
  if (loaded) {
    return (
      <div className="showcase grid justify-items-center overflow-y-hidden">
        <Balances {...props} />
        <Mint {...props} selectedToken={selectedToken} selectedTraits={selectedTraits} />
        <Gallery {...props} selectedToken={selectedToken} selectedTokenHandler={selectedTokenHandler} />
        <Customize {...props} selectedToken={selectedToken} selectedTraits={selectedTraits} />

        <div className="w-4/6 mt-1">
          <Traits {...props} selectedTraits={selectedTraits} selectedTraitsHandler={selectedTraitsHandler} />
        </div>

      </div>
    )
  } else {
    return (
      <div className="showcase grid justify-items-center overflow-y-hidden">
        <p>Connect to Ethereum Mainnet to continue</p>
      </div>
    )
  }
}

export default Customoose;
