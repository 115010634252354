import React, { useState, useEffect } from "react";
import { Contract } from '@ethersproject/contracts';
import { ethers } from "ethers";
import { setCharAt, letterToNumber } from '../../utils/strings.js';
import { ImArrowRight } from 'react-icons/im';
import SetConfig from "../SetConfig";
import BurnFrameButton from "../BurnFrameButton";
import './index.css';

var _ = require('lodash');

const BLANK_SVG = 'data:image/svg+xml;base64,PHN2ZyBpZD0ibW9vc2Utc3ZnIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaW5ZTWluIG1lZXQiIHZpZXdCb3g9IjAgMCAzMiAzMiI+PHN0eWxlPiNtb29zZS1zdmd7c2hhcGUtcmVuZGVyaW5nOiBjcmlzcGVkZ2VzO308L3N0eWxlPjwvc3ZnPg=='
const ERROR_SVG = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjIiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCgkgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIzMnB4IiBoZWlnaHQ9IjMycHgiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaW5ZTWluIG1lZXQiIHZpZXdCb3g9IjAgMCAzMiAzMiI+CjxwYXRoIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYsIDgpIiBmaWxsPSIjQkIwQjJGIiBkPSJNMTAsMEwwLDE2aDIwTDEwLDB6IE0xMSwxMy45MDhIOXYtMmgyVjEzLjkwOHogTTksMTAuOTA4di02aDJ2Nkg5eiIvPgo8L3N2Zz4K'

function Customize(props) {
    let [tokenConfig, setTokenConfig] = useState();
    let [desiredConfig, setDesiredConfig] = useState();
    let [selectedSvg, setSelectedSvg] = useState(BLANK_SVG);
    let [imgSvg, setImgSvg] = useState(BLANK_SVG);
    let [price, setPrice] = useState(0);
    let [value, setValue] = useState(0);
    let [allowance, setAllowance] = useState();

    useEffect(() => {
        let customContract = new Contract(props.customAddress, props.customAbi, props.library)

        async function getImage() {
            try {
                var rawSvg = await customContract.configToSVG(desiredConfig)
                var encodedSvg = btoa(rawSvg)
                setImgSvg('data:image/svg+xml;base64,' + encodedSvg)
            } catch {
                setImgSvg(ERROR_SVG)
            }
        }

        if (props.selectedToken >= 0) {
            getImage()
        }
    }, [props, desiredConfig])

    useEffect(() => {
        let tokenContract = new Contract(props.tokenAddress, props.tokenAbi, props.library)
        let customContract = new Contract(props.customAddress, props.customAbi, props.library)

        async function getAllowance() {
            var newAllowance = await tokenContract.allowance(props.account, props.customAddress)
            newAllowance = ethers.utils.formatUnits(newAllowance, 'ether')
            setAllowance(parseFloat(newAllowance))
        }

        async function getConfig() {
            var rawConfig = await customContract._tokenIdToConfig(props.selectedToken)
            getSelectedImage(rawConfig)
            setTokenConfig(rawConfig)
        }
 
        async function getSelectedImage(tokenConfig) {
            try {
                var rawSvg = await customContract.configToSVG(tokenConfig)
                var encodedSvg = btoa(rawSvg)
                setSelectedSvg('data:image/svg+xml;base64,' + encodedSvg)
            } catch {
                setSelectedSvg(BLANK_SVG)
            }
        }
        
        if (props.selectedToken >= 0) {
            getConfig()
        }
        getAllowance()
    }, [props])

    useEffect(() => {
        async function getDesiredConfig() {
            var newDesiredConfig = tokenConfig
            for (let [traitIndex, traitValue] of Object.entries(props.selectedTraits)) {
                newDesiredConfig = setCharAt(newDesiredConfig, traitIndex, traitValue)
            }
            setDesiredConfig(newDesiredConfig)
        }

        if (tokenConfig && Object.entries(props.selectedTraits)) {
            getDesiredConfig()
        }
    }, [props, tokenConfig])

    useEffect(() => {
        let customContract = new Contract(props.customAddress, props.customAbi, props.library)

        async function getPrice() {
            var [newPrice] = await customContract.getCustomizationPrice(props.selectedToken, desiredConfig)
            newPrice = ethers.utils.formatUnits(newPrice, 'ether')
            setPrice(newPrice)
        }

        async function getValue() {
            var newValue = await customContract._tokenIdToStoredTrax(props.selectedToken)
            newValue = ethers.utils.formatUnits(newValue, 'ether')
            setValue(newValue)
        }

        if (tokenConfig && desiredConfig) {
            getPrice()
            getValue()
        }
    }, [props, tokenConfig, desiredConfig])

    if (props.selectedToken >= 0) {
        return (
            <div className="my-4 grid w-4/6 justify-items-center items-center p-3 bg-gray-100 bg-opacity-25">
                <h2>Customization</h2>
                <div className="grid grid-cols-3 w-4/6 text-center items-center">
                    <div className="flex flex-col">
                        <img key="customoose-preview" className="gallery-img" src={selectedSvg} alt="NFT" />
                        <p>Before</p>
                    </div>
                    <div>
                        <ImArrowRight className="arrow" />
                    </div>
                    <div className="flex flex-col">
                        <img key="customoose-preview" className="gallery-img" src={imgSvg} alt="NFT" />
                        <p>After</p>
                    </div>
                </div>
                {
                    props.selectedToken >= 0 ?
                        <div className="mt-8 grid grid-cols-2 w-4/6">
                            <div className="flex flex-col items-center">
                                <SetConfig {...props} desiredConfig={desiredConfig} allowance={allowance} price={price} />
                                <p>Spend {price} TRAX</p>
                            </div>

                            <div className="flex flex-col items-center">
                                <BurnFrameButton {...props} />
                                <p>Receive {value} TRAX</p>
                            </div>
                        </div>
                    :
                    <p>Select a frame</p>
                }
            </div>
        )
    } else {
        return (
            <p>Select a frame</p>
        )
    }
}

export default Customize
