import React from 'react';
import {
  Link
} from "react-router-dom";
import { ReactComponent as Moose1 } from '../../assets/moose1.svg';
import { ReactComponent as Moose2 } from '../../assets/moose2.svg';
import { ReactComponent as Moose3 } from '../../assets/moose3.svg';
import { ReactComponent as Moose4 } from '../../assets/moose4.svg';
import { ReactComponent as Moose5 } from '../../assets/moose5.svg';

function Home(props) {
    return (
      <div className="showcase grid justify-items-center">
        <div className='container w-3/5 grid grid-cols-5 gap-2 place-items-center mt-6 mb-6'>
          <div className="border-2 border-black w-full h-full"><Moose2 /></div>
          <div className="border-2 border-black w-full h-full"><Moose1 /></div>
          <div className="border-2 border-black w-full h-full"><Moose3 /></div>
          <div className="border-2 border-black w-full h-full"><Moose4 /></div>
          <div className="border-2 border-black w-full h-full"><Moose5 /></div>
        </div>
        <div className='container card'>
          <p className="p-5 font-black text-left">
            <span className="text-3xl font-black mr-1">Moose Trax</span> creates collections of fully on-chain pixel art NFTs. 
          </p>

          <p className="p-5 font-black text-left">
            <span className="text-3xl font-black mr-1">Moose Trax OG</span> is a collection of 10,000 unique and randomly generated on-chain avatars.
            The drop used staking and burning mechanics, resulting in 2266 of our precious Moose being burned and lost forever.
            The remaining Moose can be staked to receive TRAX tokens until the claim period ends.
          </p>

          <p className="p-5 font-black text-left">
            <span className="text-3xl font-black mr-1">CustoMoose</span> is a collection of 10,000 customizable FRAME tokens. FRAME owners can add
            traits to their token at any time. Use TRAX rewards from holding an OG Moose to deck out your Moose.
          </p>

          <p className="p-5 font-black text-left">
            <span className="text-3xl font-black mr-1">Why "on-chain"?</span> Moose Trax stands out among other NFT projects because the metadata 
            and images are stored entirely on-chain. The majority of NFT projects store images off-chain or behind an API owned by the project team.
            If those teams stop maintaining the API or delete the image from storage, your NFT will no longer return an image. Our contracts contain
            all of the metadata to create the image without requiring maintenance from the project team. As long as Ethereum exists, you will be able
             to access your NFT and all of its metadata.
          </p>

          <p className="p-5 font-black text-left">
            To learn more about this project and read about our releases, visit our <Link className="underline" to="/about">about</Link> page.
          </p>

        </div>

        <div className='container card'>
          <p className="p-5 font-black text-center text-sm">
            Disclaimer: Your purchase from any NFT originally produced by Moose Trax does not constitute a financial investment.
            You are buying a piece of art that lives on the Ethereum blockchain. The TRAX token is a utility token used in our
            ecosystem. It is not an investment and has no economic value.
          </p>
        </div>

      </div>
    )
}
 
export default Home;