import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MULTICALL_ADDRESSES, DAppProvider, ChainId } from '@usedapp/core'

const config = {
  multicallAddresses: {
    // 1337: "",
    31337: "0x0b306bf915c4d645ff596e518faf3f9669b97016",
    ...MULTICALL_ADDRESSES,
  },
  supportedChains: [
    ChainId.Mainnet,
    ChainId.Goerli,
    ChainId.Kovan,
    ChainId.Rinkeby,
    ChainId.Ropsten,
    ChainId.xDai,
    ChainId.BSC,
    ChainId.Localhost,
    ChainId.Hardhat
  ],
};

ReactDOM.render(
  <React.StrictMode>
    <DAppProvider config={config}>
      <App />
    </DAppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
