import React from "react";
import { Contract } from '@ethersproject/contracts';
import { useContractFunction } from '@usedapp/core';

function UnstakeAllButton(props) {
    let tokenContract = new Contract(props.tokenAddress, props.tokenAbi, props.library)
    const { state, send } = useContractFunction(tokenContract, 'unstakeAll', { transactionName: 'Unstake all' })

    const onUnstakePressed = async () => {
        send()
        return
    };

    return (
        <button className="btn-light mx-1 px-2 py-.75 rounded-sm" id="unstakeAllButton" onClick={onUnstakePressed} disabled={props.disabled}>
            Unstake All
        </button>
    )
}

export default UnstakeAllButton
