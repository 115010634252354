import React, { useReducer } from "react";
import { Contract } from '@ethersproject/contracts';
import { useContractFunction } from '@usedapp/core';

import ApproveTokenButton from "../ApproveTokenButton";

function SetConfig(props) {
    let customContract = new Contract(props.customAddress, props.customAbi, props.library)
    const { state, send } = useContractFunction(customContract, 'setTokenConfig', { transactionName: 'Set token config' })

    const onButtonPressed = async () => {
        if (props.selectedToken >= 0 && props.desiredConfig) {
            send(props.selectedToken, props.desiredConfig)
        } else {
            alert("Your inputs suck")
        }
        return
    };

    return (
        <form autoComplete="off" className="flex">
            {
                props.allowance < parseFloat(props.price) ?
                    <ApproveTokenButton {...props} />
                    :
                    <button className='btn px-2 text-black bg-gray-200 rounded-r-sm' type="button" id="configButton" onClick={onButtonPressed} disabled={false}>
                        Customize Frame
                    </button>
            }
        </form>
    )
}

export default SetConfig
