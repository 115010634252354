import React, { useState, useEffect } from 'react';
import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { useEthers } from '@usedapp/core'
import "tailwindcss/tailwind.css"

import Navbar from './components/Navbar'
import Home from './components/Home'
import About from './components/About'
import Staking from './components/Staking'
import Customoose from './components/Customoose'

import rawCustomAbi from "./abis/Customoose.json";
import rawNftAbi from "./abis/Moose.json";
import rawTokenAbi from "./abis/Token.json";
import rawTraits from "./data/customoose_traits.json";

import "./App.css"

// static variables

// mainnet
let MOOSE_ADDRESS = '0x3146Dd9c200421A9c7d7b67bd1b75Ba3e2c15310';
let TOKEN_ADDRESS = '0xc92E730EeBaCA3d16d6C17F7F4646DcE923663e8';
let CUSTOM_ADDRESS = '0xc91D89828Cd0d635d0475eC6785c497dC1bF240F';

// testnet
// let MOOSE_ADDRESS = '0x72FC4A79Ea36Ff8bacd948435d2D988C60f2d267';
// let TOKEN_ADDRESS = '0x5444Eb7DD428E5a476Dd25e9D89F949C59Ae2a03';
// let CUSTOM_ADDRESS = '0xBc0000494A702549C6706565bE20c32e4F7EF0d7';

// local
// let MOOSE_ADDRESS = '0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0';
// let TOKEN_ADDRESS = '0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9';
// let CUSTOM_ADDRESS = '0x5FC8d32690cc91D4c39d9d3abcBD16989F875707';

// all networks
let BURN_ADDRESS = '0x000000000000000000000000000000000000dEaD';

function App(props) {
  const [burnAddress, setBurnAddress] = useState()
  const [nftAddress, setNftAddress] = useState()
  const [nftAbi, setNftAbi] = useState(null)
  const [customAddress, setCustomAddress] = useState()
  const [customAbi, setCustomAbi] = useState()
  const [tokenAddress, setTokenAddress] = useState()
  const [tokenAbi, setTokenAbi] = useState()
  const [traits, setTraits] = useState()
  const [block, setBlock] = useState()
  const { activateBrowserWallet, active, account, library, chainId } = useEthers()

  useEffect(() => {
    setBurnAddress(BURN_ADDRESS)
    setNftAddress(MOOSE_ADDRESS)
    setNftAbi(rawNftAbi)
    setCustomAddress(CUSTOM_ADDRESS)
    setCustomAbi(rawCustomAbi)
    setTokenAddress(TOKEN_ADDRESS)
    setTokenAbi(rawTokenAbi)
    setTraits(rawTraits)
    if (active) {
      activateBrowserWallet()
    }
  }, [props, active, activateBrowserWallet])

  useEffect(() => {
    async function onBlock() {
      setBlock(library.blockNumber)
    }

    if (library) {
      library.on('block', onBlock)
    }
  }, [library])

  let newProps = {
    ...props,
    block: block,
    burnAddress: burnAddress,
    nftAddress: nftAddress,
    nftAbi: nftAbi,
    customAddress: customAddress,
    customAbi: customAbi,
    tokenAddress: tokenAddress,
    tokenAbi: tokenAbi,
    traits: traits,
    account: account,
    library: library,
    active: active,
    chainId: chainId
  }

  return (
    <div className="grid grid-flow-row">
      <div className="main overflow-y-hidden overflow-y-scroll">
        <Router>
          <Navbar {...newProps} />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/customoose">
              <Customoose {...newProps} />
            </Route>
            <Route path="/staking">
              <Staking {...newProps} />
            </Route>
          </Switch>
        </Router>
      </div>
    </div>
  )
}

export default App